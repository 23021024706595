import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB9xoFX04rVti3B0kVz1VSwHi-t_D-6fi8",
  authDomain: "nextzoft.firebaseapp.com",
  projectId: "nextzoft",
  storageBucket: "nextzoft.appspot.com",
  messagingSenderId: "431351306234",
  appId: "1:431351306234:web:79dd29e8c0114440bd7643",
  measurementId: "G-FKW7HEW0RD",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);
