import webAppDevelopment from "../assets/img/WebAppDevelopment.png";
import mobileAppDevelopment from "../assets/img/MobileAppDevelopment.png";
import searchEngineOptimization from "../assets/img/SearchEngineOptimization.png";
import socialMediaMarketing from "../assets/img/SocialMediaMarketing.png";
import desktopAppDevelopment from "../assets/img/DesktopAppDevelopment.png";
import ShopSxy from "../assets/img/ShopSxy.png";
import ChicMox from "../assets/img/ChicMox.png";
import InternetSpeedCheck from "../assets/img/InternetSpeedCheck.png";
import Bacu from "../assets/img/Bacu.png";
import BacuPro from "../assets/img/BacuPro.png";
import ExchangeBari from "../assets/img/ExchangeBari.png";
import WaterBubbleShooter from "../assets/img/WaterBubbleShooter.png";

export const FIREBASE_COLLECTION_CONTACT = "Contact";

export const projectList = [
  {
    title: "Shop Sxy",
    description: "Android - iOS",
    imgUrl: ShopSxy,
  },
  {
    title: "Chic Mox",
    description: "Android - iOS",
    imgUrl: ChicMox,
  },
  {
    title: "Internet Speed Check",
    description: "Android",
    imgUrl: InternetSpeedCheck,
  },
  {
    title: "Bacu",
    description: "iOS",
    imgUrl: Bacu,
  },
  {
    title: "Bacu Pro",
    description: "iOS",
    imgUrl: BacuPro,
  },
  {
    title: "Exchange Bari",
    description: "Android",
    imgUrl: ExchangeBari,
  },
  {
    title: "Water Bubble Shooter",
    description: "Android",
    imgUrl: WaterBubbleShooter,
  },
];

export const servicesList = [
  {
    title: "Web Development",
    imgUrl: webAppDevelopment,
  },
  {
    title: "Mobile App Development",
    imgUrl: mobileAppDevelopment,
  },
  {
    title: "Search Engine Optimization",
    imgUrl: searchEngineOptimization,
  },
  {
    title: "Social Media Marketing",
    imgUrl: socialMediaMarketing,
  },
  {
    title: "Desktop App Development",
    imgUrl: desktopAppDevelopment,
  },
];
