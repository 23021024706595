import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo2.png";
import linkedInIcon from "../assets/img/LinkedInIcon.svg";
import facebookIcon from "../assets/img/FacebookIcon.svg";
import instagramIcon from "../assets/img/InstagramIcon.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="#">
                <img src={linkedInIcon} alt="Icon" />
              </a>
              <a href="#">
                <img src={facebookIcon} alt="Icon" />
              </a>
              <a href="#">
                <img src={instagramIcon} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
