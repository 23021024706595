import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import { FIREBASE_COLLECTION_CONTACT } from "./Constants";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({
    succes: false,
    message: " ",
  });

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({
      succes: false,
      message: " ",
    });

    if (formDetails.firstName.length === 0) {
      setStatus({
        succes: false,
        message: "Enter first name",
      });
      return;
    }

    if (formDetails.lastName.length === 0) {
      setStatus({
        succes: false,
        message: "Enter last name",
      });
      return;
    }

    if (formDetails.email.length === 0) {
      setStatus({
        succes: false,
        message: "Enter email",
      });
      return;
    }
    if (formDetails.message.length === 0) {
      setStatus({
        succes: false,
        message: "Enter message",
      });
      return;
    }

    setButtonText("Sending...");

    await setDoc(
      doc(firestore, FIREBASE_COLLECTION_CONTACT, formDetails.email),
      {
        fname: formDetails.firstName,
        lname: formDetails.lastName,
        mobile: formDetails.phone,
        message: formDetails.message,
        email: formDetails.email,
      }
    )
      .then(() => {
        setStatus({ succes: true, message: "Message sent successfully" });
        setFormDetails(formInitialDetails);
      })
      .catch(() => {
        setStatus({
          succes: false,
          message: "Something went wrong, please try again later.",
        });
      });
    setButtonText("Send");
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.firstName}
                          placeholder="First Name"
                          onChange={(e) =>
                            onFormUpdate("firstName", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.lastName}
                          placeholder="Last Name"
                          onChange={(e) =>
                            onFormUpdate("lastName", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="email"
                          value={formDetails.email}
                          placeholder="Email Address"
                          onChange={(e) =>
                            onFormUpdate("email", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="tel"
                          value={formDetails.phone}
                          placeholder="Phone Number (Optional)"
                          onChange={(e) =>
                            onFormUpdate("phone", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea
                          rows="6"
                          value={formDetails.message}
                          placeholder="Message"
                          onChange={(e) =>
                            onFormUpdate("message", e.target.value)
                          }
                        ></textarea>
                        {status.message && (
                          <Col>
                            <p
                              className={
                                status.succes === false ? "danger" : "success"
                              }
                            >
                              {status.message}
                            </p>
                          </Col>
                        )}
                        <button type="submit">
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
